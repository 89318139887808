
const pagenav = $('.pagenav');
const pageNumbers = $('.page-numbers');

const pagenavLength = pagenav.children().length;

console.log('pagenavLength');
console.log(pagenavLength);

if(pagenavLength <= 2 && pageNumbers.hasClass('prev')){
  pagenav.addClass('col3');
  pagenav.addClass('flexstart');
} else if(pagenavLength <= 2 && pageNumbers.hasClass('next')){
  pagenav.addClass('col3');
  pagenav.addClass('flexend');
}

if(pageNumbers.hasClass('prevall')){
  pagenav.addClass('flexstart');

} else if(pageNumbers.hasClass('nextall')){
  pagenav.addClass('flexend');
}