
//topに戻るアイコン
var topBtn = $('.pageTop');
topBtn.hide();
$(window).on('scroll', function () {
  if ($(this).scrollTop() > 400) {
    topBtn.fadeIn();
  } else {
    topBtn.fadeOut();
  } 11
});

//スクロールしてTopに戻る
topBtn.on('click', function () {
  $('body,html').animate({
    scrollTop: 0
  }, 700);
  return false;
});