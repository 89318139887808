import isSp from "./isSpSize";

const tabList = $('.tabList');

function tabResize () {
  $(window).on('load resize', function(){
    var w = $(window).width();
    var x = 768;
    if (w < x) {
      //画面サイズが768px未満のときの処理
      $(tabList).removeClass('open');
      tabList.addClass('isOpen');
      
      // if(tabList.children().hasClass('pageOpen')){
        //   $('.abList_item .pageOpen').addClass('accordion');
        // }
        
        
        
      } else {
        //それ以外のときの処理
        $(tabList).addClass('open');
        tabList.removeClass('isOpen');
    }

    
  });

}

function tabAccordion() {
  if(isSp()) {

    if(tabList.hasClass('open')){
      $(tabList).removeClass('open');
      
    } else {
      $(tabList).addClass('open');
    }
  
  }
}

tabResize();

const js_tab = $('.js_tabItem');
const js_tab_target = $('.js_tab_target');
const cls = 'active';

js_tab.on('click', function () {
  
  if($(this).hasClass('active')){
    js_tab.removeClass(cls);
    
  } else{

    $(this).addClass(cls);
  }

  const this_category = $(this).data('slug');

  js_tab.removeClass(cls);

  $(this).addClass(cls);

  
  js_tab_target.removeClass(cls);

  js_tab_target.each(function () {
    const target_data = $(this).data('target');
    if (this_category === target_data) {
      $(this).addClass(cls);

    }

  });

  tabAccordion();

});

const js_accordion = $('.js_accordion');
const pageTabList = $('.pageTabList');
const accordion_cls = 'active';

pageTabList.on('click', function() {
  if(pageTabList.hasClass('isOpen')){
    pageTabList.addClass('open');
    pageTabList.removeClass('isOpen');
  }
});

js_accordion.on('click', function () {

  if($(pageTabList).hasClass('pageOpen')){
    $(pageTabList).addClass('open');
    $(js_accordion).removeClass(accordion_cls);
  } else {
    $(pageTabList).removeClass('open');
    $(js_accordion).addClass(accordion_cls);
  }
  
  js_accordion.removeClass(cls);

  $(this).addClass(cls);


  tabAccordion();

});

