
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

const header = $('.header');
const headerElem = document.querySelectorAll('.header');
const bodyScrollLockTarget = document.querySelector('#js-bodyScrollLock');

$(function () {
    // メニューボタン
    $('#js-hamburger').on('click', function () {
        $('body').toggleClass('is-hamburgerOpen');

        if ($(this).attr('aria-expanded') == 'false') {
            $(this).attr('aria-expanded', true);
            document.querySelector('#js-hamburger_text').innerHTML = '閉じる';

            $(header).addClass('is-navOpen');

            toggleBodyScrollLock();

        } else {
            $(this).attr('aria-expanded', false);
            document.querySelector('#js-hamburger_text').innerHTML = 'メニュー';

            const resetNavTitle = $('.globalNav_child');
            $(header).removeClass('is-navOpen');
            
            
            resetNavTitle.each(function() {

                if($(this).hasClass('open')){
                    $(this).slideUp();
                    $(this).removeClass('open');
                }

            });

            toggleBodyScrollLock();

        }

        const gnavHead = $('.globalNav_link');

        if($(gnavHead).hasClass('is-openHead')){
            $(gnavHead).removeClass('is-openHead');
        }

        $('.globalNav_container').css('transition', 'all 0.4s ease-out');
        setTimeout(() => {
            $('.globalNav_container').css('transition', 'none');
        }, 1000);

    });


    // メニュー内のアコーディオン
    $('.globalNav_link.accordion').on('click', function(e) {

        const navHead = $(this);

        if($(navHead).hasClass('is-openHead')){
            $(navHead).removeClass('is-openHead');

        } else{
            $(navHead).addClass('is-openHead');
            toggleBodyScrollLock();
        }
        
        if($(window).width() < 991){
            e.preventDefault();
        }

        const navTarget = '#' + $(this).attr('data-navlink');
        // const navHref = $(this).attr('href');
        $(navTarget).slideToggle();

        if($(navTarget).hasClass('open')){
            // window.location.href = navHref;
        } else{
            $(navTarget).addClass('open');
            toggleBodyScrollLock();
        }

        
    });

    function toggleBodyScrollLock() {
        if ($(headerElem).hasClass('is-navOpen')) {
          
            disableBodyScroll(bodyScrollLockTarget, {
                reserveScrollBarGap: true,

            });
        
        } else {
          
            enableBodyScroll(bodyScrollLockTarget, {
                reserveScrollBarGap: true
            });
        }
    }

});
